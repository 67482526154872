import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styles from "./SideBarStyles.module.css";
import { SideBarFooterCard } from "./modules/SideBarFooterCard/SideBarFooterCard";
import { findSectionByPath } from "./utils/SideBar.utils";
import { SectionMenuItem } from "./modules/SectionMenuItem/SectionMenuItem";
import { section_key } from "features/AdminPanel/constants/AdminPanel.constants";
import { useAdminPanelStore } from "features/AdminPanel/store/useAdminPanelStore";
import { updateUrlWithParam } from "features/Common/utils/url.utils";

export const SideBar = () => {
  const location = useLocation();
  const history = useHistory();
  const [selectedSectionId, setSelectedSectionId] = useState("");

  const adminPanelLayoutConfig = useAdminPanelStore(
    (state) => state.adminPanelLayoutConfig
  );

  const { sideBarConfig = [] } = adminPanelLayoutConfig;

  useEffect(() => {
    const { subSection } = findSectionByPath({
      config: sideBarConfig,
      path: location.pathname,
    });
    if (subSection) {
      setSelectedSectionId(subSection.id);
    }
  }, [location.pathname, sideBarConfig]);

  const handleSectionClick = ({ subSection = {} }) => {
    const { id, path, section_key: sectionKeyValue } = subSection;
    setSelectedSectionId(id);
    if (path) {
      history.replace(
        sectionKeyValue
          ? updateUrlWithParam({
              path,
              key: section_key,
              value: sectionKeyValue,
            })
          : path
      );
    }
  };

  return (
    <div className={styles.menuWrapper}>
      <div className={styles.sectionWrapper}>
        {sideBarConfig.map((section) => (
          <div key={section.id} className={styles.sectionContainer}>
            <div className={styles.sectionTitle}>{section.section_title}</div>
            {section.sub_section?.map((subSection) => {
              const isActive = subSection.id === selectedSectionId;
              return (
                <SectionMenuItem
                  key={subSection.id}
                  subSection={subSection}
                  handleSectionClick={handleSectionClick}
                  isActive={isActive}
                />
              );
            })}
          </div>
        ))}
      </div>
      <div className={styles.footer}>
        <SideBarFooterCard />
      </div>
    </div>
  );
};
