import { dataProvider } from "data";
import { apiMethods } from "data/api.constants";
import {
  UPDATE_ADMIN_PANEL_SETTINGS,
  GET_KEY_WISE_SETTINGS,
} from "../constants/AdminPanel.api";
import { is_empty } from "features/Common/utils/common.utils";
import { FEATURE_STATUS } from "features/Account/Account.constants";
import { baseApiClient } from "data/modules/api/api.client";

export const updateAdminPanelSettings = ({ endPoint, payload }) => {
  const apiUrl = endPoint ?? UPDATE_ADMIN_PANEL_SETTINGS;
  return baseApiClient.post(apiUrl, payload);
};

export const getKeyLevelSettings = (keys) => {
  if (is_empty(keys)) return;

  const queryString = keys.map(encodeURIComponent).join(",");
  return dataProvider.custom_request(
    `${GET_KEY_WISE_SETTINGS}?feature_keys=${queryString}`,
    apiMethods.GET
  );
};

export const getSectionStates = ({ status }) => {
  const isPlanLocked = status === FEATURE_STATUS.PLAN_LOCKED;
  const verificationNotCompleted = [
    FEATURE_STATUS.ACCESS_REQUESTED,
    FEATURE_STATUS.ACCESS_DENIED,
    FEATURE_STATUS.ACCESS_LOCKED,
  ].includes(status);

  const disableSwitch = isPlanLocked || verificationNotCompleted;

  return {
    isPlanLocked,
    verificationNotCompleted,
    disableSwitch,
  };
};
