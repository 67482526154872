import { isRequestSuccessful } from "utils/Utils";
import { logError } from "utils/error";
import { notification_color_keys, useNotifications } from "utils/hooks";
import { useAdminPanelStore } from "features/AdminPanel/store/useAdminPanelStore";
import { updateAdminPanelSettings } from "features/AdminPanel/utils/AdminPanel.utils";

export const useAccountSectionUnlocking = () => {
  const setSectionConfig = useAdminPanelStore(
    (state) => state.setSectionConfig
  );
  const sectionConfig = useAdminPanelStore((state) => state.sectionConfig);
  const { notify } = useNotifications();

  const handleSectionChange = async ({
    payload = {},
    endPoint,
    ...restParams
  }) => {
    if (!payload.feature_key) return;

    try {
      const response = await updateAdminPanelSettings({
        endPoint,
        payload,
      });
      if (isRequestSuccessful(response?.status))
        setSectionConfig({
          ...sectionConfig,
          [payload.feature_key]: {
            ...restParams,
            value: payload.feature_value,
          },
        });
    } catch (error) {
      notify(error.message, notification_color_keys.error);
      logError({
        error,
        occuredAt: "Error updating section toggle",
        when: "calling handleSectionChange",
      });
    }
  };

  return {
    handleSectionChange,
  };
};
