import React from "react";
import { getProfilePicture } from "../utils/SideBarFooterCard.utils";
import ExlyImage from "common/Components/ExlyImage";
import { getCreatorHostsiteURL } from "features/Account/utils/getCreatorPageDetails";
import { getAuthData } from "utils/AuthUtil";
import styles from "./SideBarFooterCardStyles.module.css";
import classnames from "classnames";

export const SideBarFooterCard = () => {
  const auth = getAuthData();
  const profilePicture = getProfilePicture();
  const webpageUrl = getCreatorHostsiteURL()?.url;

  return (
    <div className={styles.cardWrapper}>
      <ExlyImage
        className={styles.profileImg}
        src={profilePicture}
        alt="display_image"
      />
      <div className={styles.nameWrapper}>
        <div className={classnames(styles.name, "ellipsis")}>
          {auth.display_name}
        </div>
        <a
          className={classnames(styles.link, "ellipsis")}
          target="_blank"
          rel="noreferrer"
          href={webpageUrl}
        >
          {webpageUrl}
        </a>
      </div>
    </div>
  );
};
