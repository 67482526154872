import { create } from "zustand";
import React from "react";

export const useAdminPanelStore = create((set) => ({
  adminPanelSettingsConfig: {}, // key that keeps config of settings and sidebar
  setAdminPanelConfig: (value) =>
    set((state) => ({
      adminPanelSettingsConfig: { ...state.adminPanelSettingsConfig, ...value },
    })),
  adminPanelSettingsLoading: false, // loading state for adminPanelSettingsConfig
  setAdminPanelSettingsLoading: (value) =>
    set({ adminPanelSettingsLoading: value }),
  requestVerificationKey: null, // A feature key is saved, when request verification modal is opened.
  setRequestVerificationKey: (value) => set({ requestVerificationKey: value }),
  sectionConfig: {}, // AN OBJECT CONTAINING KEY VALUE PAIR OF  SECTION CONFIG
  setSectionConfig: (value) => set({ sectionConfig: value }),
  sectionConfigLoading: false, // section config loading state
  setSectionConfigLoading: (value) => set({ sectionConfigLoading: value }),
  isActionFieldsModalOpen: false, // used to open edit and delete actions drawer in mobile
  setIsActionFieldsModalOpen: (value) =>
    set({ isActionFieldsModalOpen: value }),
  selectedRecord: {}, // used to select a record, on which edit and delete actions will be performed.
  setSelectedRecord: (value) => set({ selectedRecord: value }),
  // admin panel layout management states
  adminPanelLayoutConfig: {
    sideBarConfig: [],
    hideChildren: false,
    headerTitle: "",
    hideNav: false,
    classes: {},
    hideHeader: false,
    headerRight: <></>,
    backUrl: null,
  },
  setAdminPanelLayoutConfig: (value) =>
    set((state) => ({
      adminPanelLayoutConfig: {
        ...state.adminPanelLayoutConfig,
        ...value,
      },
    })),
  isSeeReferenceModalOpen: null, // state to check if see reference modal is open
  setIsSeeReferenceModalOpen: (value) =>
    set({ isSeeReferenceModalOpen: value }),
}));
