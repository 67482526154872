import React from "react";
import styles from "./SectionMenuItemStyles.module.css";
import ExlyImage from "common/Components/ExlyImage";
import classnames from "classnames";

export const SectionMenuItem = ({
  handleSectionClick = () => {},
  subSection,
  isActive,
}) => {
  return (
    <div
      key={subSection.id}
      className={classnames(
        styles.subSectionWrapper,
        isActive && styles.activeSubSectionWrapper
      )}
      style={{
        "--primary-color": subSection.primary_color,
        "--bg-color": subSection.secondary_color,
      }}
      onClick={() => handleSectionClick({ subSection })}
    >
      <ExlyImage
        src={
          isActive
            ? subSection.side_menu_active_icon
            : subSection.side_menu_icon
        }
        fetchWidth={60}
        className={styles.sideMenuIcon}
      />
      <div
        className={classnames(
          styles.subSectionTitle,
          isActive && styles.activeSubSectionTitle
        )}
      >
        {subSection.sub_section_title}
      </div>
    </div>
  );
};
