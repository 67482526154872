import React from "react";
import styles from "./SectionHeadingStyles.module.css";
import { useDesktopMediaQuery } from "features/Common/hooks/useDesktopMediaQuery";
import { ArrowBack } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import classnames from "classnames";

export const SectionHeading = ({
  heading,
  backUrl,
  showBackIcon = false,
  classes = {},
  headerRight = <></>,
}) => {
  const history = useHistory();
  const isDesktop = useDesktopMediaQuery();
  const onBackClick = () => {
    if (backUrl) history.replace(backUrl);
    else history.goBack();
  };
  return (
    <div className={classnames(styles.heading, classes.heading)}>
      {(!isDesktop || showBackIcon) && (
        <ArrowBack className={styles.backIcon} onClick={onBackClick} />
      )}
      <div className="tw-flex tw-justify-between w-100 tw-items-center">
        <div className={classnames(styles.headingText, classes.headingText)}>
          {heading}
        </div>
        {headerRight}
      </div>
    </div>
  );
};
